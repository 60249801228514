//base64转码（压缩完成后的图片为base64编码，这个方法可以将base64编码转回file文件）
function dataURLtoFile(dataurl) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], {
    type: mime
  });
}
//压缩图片
function compressImg(file) {
  console.log(file);
  var src;
  var files;
  var fileSize = parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(2);
  if (fileSize <= 0) {
    return;
  }
  console.log('oldFileSize', fileSize);
  var read = new FileReader();
  read.readAsDataURL(file);
  return new Promise(function(resolve, reject) {
    read.onload = function(e) {
      var img = new Image();
      img.src = e.target.result;
      img.onload = function() {
        //默认按比例压缩
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        var base64;
        // 创建属性节点
        const { width: originWidth, height: originHeight } = img; // 最大尺寸限制
        const maxWidth = 1000;
        const maxHeight = 1000; // 目标尺寸
        let targetWidth = originWidth;
        let targetHeight = originHeight;
        if (originWidth > maxWidth || originHeight > maxHeight) {
          if (originWidth / originHeight > 1) {
            // 宽图片
            targetWidth = maxWidth;
            targetHeight = Math.round(maxWidth * (originHeight / originWidth));
          } else {
            // 高图片
            targetHeight = maxHeight;
            targetWidth = Math.round(maxHeight * (originWidth / originHeight));
          }
        }
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        ctx.drawImage(this, 0, 0, targetWidth, targetHeight);
        // canvas.toBlob(function(blob) {
        //   resolve(blob);
        // }, 'image/png');

        if (fileSize < 0.5) {
          //如果图片小于一兆 那么不执行压缩操作
          base64 = canvas.toDataURL(file['type'], 0.8);
        } else if (fileSize > 0.5 && fileSize < 1) {
          //如果图片大于1M并且小于2M 那么压缩0.5
          base64 = canvas.toDataURL(file['type'], 0.7);
        } else if (fileSize > 1 && fileSize < 1.5) {
          base64 = canvas.toDataURL(file['type'], 0.6);
        } else {
          //如果图片超过2m 那么压缩0.2
          base64 = canvas.toDataURL(file['type'], 0.2);
        }
        // 回调函数返回file的值（将base64编码转成file）
        files = dataURLtoFile(base64); //如果后台接收类型为base64的话这一步可以省略
        console.log('NewFileSize', parseFloat(parseInt(files['size']) / 1024 / 1024).toFixed(2));
        resolve(files);
      };
    };
  });
}

function compressImg1(file) {
  const _this = this;
  return new Promise(resolve => {
    const reader = new FileReader();
    const image = new Image();
    image.onload = imageEvent => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      let width = image.width;
      let height = image.height;
      let rate = 1150 / width > 1;
      let tate = 1150 / height > 1;
      if (!rate) {
        let product = 1150 / width;
        width = Math.floor(width * product * 100) / 100;
        height = Math.floor(height * product * 100) / 100;
      } else if (!tate) {
        let product = 1150 / height;
        width = Math.floor(width * product * 100) / 100;
        height = Math.floor(height * product * 100) / 100;
      }
      console.log(width, height, '111');
      canvas.width = width;
      canvas.height = height;
      context.clearRect(0, 0, width, height);
      context.drawImage(image, 0, 0, width, height);
      const dataUrl = canvas.toDataURL(file.type);
      const blobData = _this.dataURItoBlob(dataUrl, file.type);
      resolve(blobData);
    };
    reader.onload = e => {
      image.src = e.target.result;
    };
    reader.readAsDataURL(file);
  });
}

function dataURItoBlob(dataURI, type) {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {
    type: type
  });
}

//结尾处将该方法暴露出来供外部调用
export default {
  compressImg,
  dataURItoBlob
};
