<template>
  <div class="filelist-list">
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="previewList" />
    <draggable class="dragWrap" v-if="myList.length > 0" v-model="myList" @end="onEnd">
      <div :style="{ top: item.top }" ref="itemPicRef" class="itemPic" v-for="(item, index) in myList" :key="index">
        <!-- <div class="picture" @click="con(index)"> 此方法用于修复旋转bug-->
        <!-- {{ item.clmc.substring(0, item.clmc.lastIndexOf('\.')) }} -->
        <div class="picture">
          <el-image :src="item.fileUrl" :fit="fit" ref="img" v-if="item.dataFormat === 'img'"></el-image>
          <el-image :src="pdfFile" :fit="fit" ref="img" v-if="item.dataFormat === 'pdf'"></el-image>
          <el-image
            :src="imgFile"
            :fit="fit"
            ref="img"
            class="test"
            v-if="item.dataFormat !== 'img' && item.dataFormat !== 'pdf' && item.clmc.indexOf('.mp4') < 0"
          ></el-image>
          <video
            controls
            class="video"
            v-if="item.dataFormat !== 'img' && item.dataFormat !== 'pdf' && item.clmc.indexOf('.mp4') >= 0"
          >
            <source :src="item.fileUrl" type="video/mp4" />
          </video>
          <div class="delete" v-if="check">
            <div class="i-wrap">
              <!-- 删除 -->
              <i class="el-icon-delete" @click="delImage(item, index)"></i>
            </div>
          </div>
          <div class="hide">
            <div class="i-wrap">
              <!-- <i class="el-icon-refresh" @click="rotateImg(index)"></i> -->
              <!-- 预览 -->
              <i v-if="item.dataFormat === 'img'" class="el-icon-zoom-in" @click="largeImage(index)"></i>
              <!-- 下载 -->
              <i v-if="item.dataFormat !== 'img'" class="el-icon-download" @click="openFile(item.fileUrl)"></i>
              <!-- 删除 -->
              <!-- <i v-if="check" class="el-icon-delete" @click="delImage(item, index)"></i> -->
            </div>
          </div>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import applicantUploadService from '@/pages/iebdc/api/apply/applicant-upload';
import draggable from 'vuedraggable';
import { Loading } from 'element-ui';
import ElImageViewer from './image-viewer';

export default {
  name: 'index',
  components: {
    draggable,
    ElImageViewer,
  },
  props: {
    uploadfileConfig: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
    sfbx: {
      type: Number,
    },
    id: {
      type: String,
    },
    check: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      imgFile: require('@/pages/iebdc/assets/images/imgWj.jpg'),
      pdfFile: require('@/pages/iebdc/assets/images/wysq/pdf.png'),
      srcList: this.list.map(({ fileUrl }) => fileUrl),
      myList: this.list.sort((a, b) => a.sxh - b.sxh),
      previewList: [],
      showViewer: false,
      fit: 'contain',
    };
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    },
    //预览图片
    largeImage(index) {
      this.showViewer = true;
      let mypreviewList = this.myList.map(({ fileUrl }) => fileUrl);
      this.previewList = mypreviewList.slice(index).concat(mypreviewList.slice(0, index));
    },
    closeViewer() {
      this.showViewer = false;
    },
    con(index) {
      this.$refs.img[index].$el.style.transform = 'initial';
      this.$refs.img[index].$el.setAttribute('data-deg', '0');
    },
    //拖拽完成后保存当前文件顺序
    onEnd() {
      this.$parent.postList(this.myList, this.uploadfileConfig, this.id);
    },
    //打开上传弹窗
    uploadWindow(id, name) {
      this.$parent.showDemo(id, name, true);
    },
    //旋转
    rotateImg(index) {
      // 问题: 点击旋转后预览功能不正常，页面不可滑动
      let deg = this.$refs.img[index].$el.getAttribute('data-deg') || 0;
      this.$refs.img[index].$el.style.transform = `rotate(${90 * ++deg}deg)`;
      this.$refs.img[index].$el.setAttribute('data-deg', deg);
    },
    startLoading() {
      this.loading = Loading.service({
        lock: true,
        text: '拼命加载中...', //可以自定义文字
        spinner: 'el-icon-loading', //自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.7)', //遮罩层背景色
        target: '.wrap', // 需要遮罩的区域
        customClass: 'mask',
      });
    },
    endLoading() {
      this.loading.close();
    },
    //根据fileId删除文件
    delImage(item, index) {
      let _this = this;
      _this.startLoading();
      applicantUploadService
        .delImage({ id: item.id })
        .then((res) => {
          if (res.success === true) {
            _this.$message.success('删除成功！');
            this.myList.splice(index, 1);
            console.log(this.myList);
            this.endLoading();
            //   this.$parent.postList(
            //     this.myList.sort((a, b) => a.sxh - b.sxh),
            //     this.uploadfileConfig,
            //     this.id
            //   );
          } else {
            _this.endLoading();
            _this.$message.error(res.message);
          }
        })
        .catch((error) => {
          _this.endLoading();
        });
    },
    //打开文件
    openFile(fileUrl) {
      this.removeStyle();
      if (fileUrl) {
        window.open(fileUrl);
      } else {
        this.$message.warning('文件不存在');
      }
    },
    openGPYModel(id, item) {
      this.$parent.reInitGPY(id, item.name);
      this.$parent.openGPYModel(false, item);
    },
    //解决项目打包上线后 点击el-image图片给body加上了overflow:hidden样式导致页面不可滑动问题
    removeStyle() {
      document.body.style.overflow = 'auto';
    },
    closeviewer() {
      this.showViewer = false;
    },
  },
  watch: {
    list: {
      deep: true,
      handler: function (n, o) {
        if (n.length === o.length) return;
        this.myList = n.sort((a, b) => a.sxh - b.sxh);
        this.srcList = n.map(({ fileUrl }) => fileUrl);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.dragWrap {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  position: relative;
  padding: 0 30px 10px 30px;
}

.itemPic {
  margin-bottom: 5px;
  .title {
    line-height: 15px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .picture {
    padding: 5px;
    border: 1px solid #d9d9d9;
    height: 120px;
    width: 120px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    &:hover .hide {
      display: block;
    }
    &:hover .delete {
      display: block;
    }
    .hide {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      top: 0;
      left: 0;
      .i-wrap {
        position: absolute;
        width: 60px;
        height: 20px;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        text-align: center;
        i {
          color: #fff;
          font-size: 20px;
          margin: 0 5px;
          cursor: pointer;
        }
      }
    }
    .delete {
      display: none;
      position: absolute;
      width: 30px;
      height: 25px;
      background: rgba(0, 0, 0, 0.4);
      top: 2px;
      right: 2px;
      z-index: 1;
      .i-wrap {
        position: absolute;
        width: 30px;
        height: 20px;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        text-align: center;
        i {
          color: #fff;
          font-size: 20px;
          margin: 0 5px;
          cursor: pointer;
        }
      }
    }
    .el-image {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 100%;
      height: 100%;
      overflow: hidden;
      line-height: 120px;
      /deep/ .el-image__inner {
        max-width: 100%;
        max-height: 100%;
        // width: auto;
        // height: auto;
      }
    }
    .video {
      display: flex;
      align-items: center;
      width: initial;
      height: initial;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .minWidth {
    min-width: 80px;
  }
  .uploadStyle {
    width: 80px;
    height: 80px;
  }
  .uploadBox {
    border: 1px dashed #e5e5e5;
  }
}
/deep/ .el-image__inner--center {
  top: 0;
  left: 0;
  transform: none;
}

/deep/ .el-image__inner,
/deep/ .el-image__placeholder,
/deep/ .el-image__error {
  width: inherit;
  height: inherit;
}
</style>
