<template>
  <div class="wrap">
    <!--高拍仪-->
    <gpy-model
      ref="GpyModelRef"
      @upload="uploadHandle"
      @update="updateHandle"
      :init-data="gpyFileList"
      v-if="isGpy === 'lt'"
    ></gpy-model>
    <gz-gpy
      ref="GpyModelRef"
      @upload="uploadHandle"
      @update="updateHandle"
      :init-data="gpyFileList"
      v-if="isGpy === 'gz'"
    ></gz-gpy>
    <jy-gpy-model
      ref="GpyModelRef"
      @upload="uploadHandle"
      @update="updateHandle"
      :init-data="gpyFileList"
      v-if="isGpy === 'jy'"
    ></jy-gpy-model>
    <ty-gpy-model
      ref="GpyModelRef"
      @upload="uploadHandle"
      @update="updateHandle"
      :init-data="gpyFileList"
      v-if="isGpy === 'ty'"
    ></ty-gpy-model>
    <div :key="needUpdateKey">
      <!-- 上传注意事项 -->
      <div class="notice" v-if="check">
        <p><span>提示：</span>1. <img :src="starIcon" />为必填材料，您必须提交才能申报</p>
        <p class="margin-left">2. 附件不大于2M，文件类型为jpg，jpeg，png，pdf</p>
        <p class="margin-left">3. 分辨率不小于728*1080</p>
        <div v-if="txtShow">
          <!-- <div> -->
          <p class="margin-left">
            4.
            能够提供房屋原值或主管税务机关通过契税征管档案能查实房产原值的，个人所得税按转让差额适用20%税率据实征收；无法确认房屋原值的，个人所得税按转让价格适用1%征收率核定征收。
          </p>
          <p class="margin-left">如您能够提供房屋原值相关凭证，请上传以下附报资料：</p>
          <p class="margin-left">（1）上次交易的增值税、契税完税证明；</p>
          <p class="margin-left">（2）支付装修费用的发票；</p>
          <p class="margin-left">（3）贷款银行出具的有效证明；</p>
          <p class="margin-left">（4）支付的手续费、公证费、其他费用的有效证明。</p>
        </div>
      </div>
      <!-- 标题 -->
      <div class="flex-space-between wrap-title wrap-title-margin">
        <div class="apply-file">
          <span class="apply-file-title">申报材料</span>
          <span class="apply-ywh">业务号：{{ this.flowInfoIng.wwywh }}</span>
        </div>

        <div class="flex refresh">
          <div class="btn-compo" @click="printApply()">
            <span>打印申请表</span>
          </div>
          <div class="btn-compo" @click="refresh()">
            <img :src="refreshIcon" />
            <span>刷新</span>
          </div>
        </div>
      </div>
      <!-- ! -->
      <div class="zlscBox">
        <div class="filelist dragFileList" v-for="(item, index) in fileData" :key="index">
          <div class="filelist-title flex flex-a_c flex-j_between">
            <div :class="check ? 'block' : 'block-check'">
              {{ index + 1 }}.
              <img v-if="item.sfbx" :src="starIcon" />
              <!-- <i v-if="item.sfbx" class="el-icon-star-off"></i> -->
              <span class="clmc">{{ item.clmc }}</span>
              <!-- <i class="el-icon-view" @click="checkSl(item)"></i> -->
              <!-- <img :src="eyeIcon" @click="checkSl(item)" /> -->
            </div>
            <div :class="check ? 'btn-wrap' : 'btn-wrap-check'">
              <!-- <el-button class="ml10" type="primary" v-if="isGetCL(item.clmc).isShowBtn">{{ isGetCL(item.clmc).name }}</el-button> -->
              <el-upload
                class="upload-demo"
                :multiple="true"
                action="no"
                :http-request="httpRequest"
                :before-upload="(file) => localUploadHandle(file, item)"
                :show-file-list="false"
                v-if="check"
              >
                <div class="btn-compo">
                  <img :src="localIcon" />
                  <span>本地上传</span>
                </div>
                <!-- <el-button size="mini" plain><img :src="localIcon" />本地上传</el-button> -->
              </el-upload>
              <div class="btn-compo" v-show="check && $hasPermission('IEBDC:SCFJ:PZSC')" @click.stop="openGPY(item)">
                <img :src="photoIcon" />
                <span>拍照上传</span>
              </div>
              <div class="btn-compo" v-show="htbh && item.clmc === '买卖合同'" @click.stop="getHt(item)">
                <i class="el-icon-tickets"></i>
                <span>提取合同</span>
              </div>
              <!-- <div class="btn-compo" v-if="check && item.fileListData.length > 0" @click.stop="delAllFiles(item)">
                <i class="el-icon-delete-solid"></i>
                <span>全部删除</span>
              </div> -->
              <div class="btn-compo" @click="showMorePic(item)">
                <i :class="[item.showMore ? 'el-icon-top' : 'el-icon-bottom']"></i>
                <span>{{ item.showMore ? '收起' : '展开' }}</span>
              </div>
            </div>
          </div>
          <file-list
            ref="fileListRef"
            :list="item.showMore ? item.fileListData : []"
            :uploadfileConfig="item.uploadfileConfig"
            :sfbx="Number(item.sfbx)"
            :id="item.id"
            :check="check"
          ></file-list>
        </div>
      </div>
      <div class="main-footer text-center" v-if="check">
        <el-button type="primary" @click="prev()">上一步</el-button>
        <!--<el-button type="primary" @click="save">保存</el-button>-->
        <el-button type="primary" v-if="showNextTag" @click="next()">下一步</el-button>
      </div>
      <e-dialog :config="openUploadInfo" class="dialog">
        <template slot="content">
          <el-carousel indicator-position="none" height="250px">
            <el-carousel-item v-for="(slide, index) in demo" :key="index">
              <p class="redPoint">材料要求：{{ slide.clyq }}</p>
              <p>图片质量要求：{{ slide.tpzlyq && slide.tpzlyq.join(',') }}</p>
              <div class="img-div">
                <el-image :src="slide.fileUrl" class="demo-img">
                  <div slot="error" class="image-slot">
                    <span>加载失败</span>
                  </div>
                </el-image>
              </div>
              <p class="img-name">{{ slide.name }}</p>
            </el-carousel-item>
          </el-carousel>
        </template>
      </e-dialog>
      <e-dialog :config="openUploadPhone" class="dialog">
        <template slot="content">
          <div class="uploadPhone">
            <img :src="ewmImg" />
            <el-button size="small" type="primary" @click="phoneFinishApp()">上传完成</el-button>
          </div>
        </template>
      </e-dialog>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import applicantUploadService from '@/pages/iebdc/api/apply/applicant-upload';
import fileList from '../component/file-list';
import { REST_SERVICE } from '@iebdc/config/';
import { Loading } from 'element-ui';
import compressImg from '@iebdc/utils/compressImg.js';
import { localDataUser } from '@iebdc/utils/local-data';
import { printApply } from '@/pages/iebdc/api/my-application';
// import applicantCertifyService from '@/pages/iebdc/api/apply/applicant-certify';
export default {
  name: 'uploadFile',
  components: {
    fileList,
  },
  props: {
    checkdisabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      starIcon: require('@/pages/iebdc/assets/images/star.png'),
      eyeIcon: require('@/pages/iebdc/assets/images/eye.jpg'),
      photoIcon: require('@/pages/iebdc/assets/images/photo.jpg'),
      localIcon: require('@/pages/iebdc/assets/images/local.jpg'),
      refreshIcon: require('@/pages/iebdc/assets/images/refresh.png'),
      userInfo: {},
      currentGPYObject: {},
      needUpdateKey: 0,
      gpyFileList: [],
      singleUpload: false,
      loading: null,
      check: '', //是否可修改
      step: '', //申请步骤状态
      djjg: '',
      subCode: '',
      subFlowname: '',
      ewmImg: '',
      multiple: false, //多张上传
      fileList: [],
      fileTimeout: null,
      qlrInfoList: [], //权利人列表
      ywrInfoList: [], //义务人列表
      fileData: [], //材料列表
      //弹窗配置项
      openUploadInfo: {
        visible: false,
        hasConfirm: false,
        hasCancel: false,
        hasBtn: false,
      },
      //手机上传弹窗配置项
      openUploadPhone: {
        title: '微信扫描二维码上传材料',
        visible: false,
        hasConfirm: false,
        hasCancel: false,
        hasBtn: false,
        height: '400px',
      },
      demo: [],
      // 上传附带额外参数
      paramsData: {
        recover: true,
      },
      // 上传文件路径
      submitFileUrl: REST_SERVICE.files.mulFileSave,
      // selectedItem: {},
      // selectedName: '',
      systemTime: '', //系统时间
      showNextTag: false, //默认不显示下一步按钮
      isGpy: '',
      isApply: true, //是否申请中
      txtShow: false,
    };
  },
  mounted() {},
  beforeDestroy() {
    this.$bus.$off('selectedTaxStatus');
  },
  computed: {
    // 下一步跳转所需数据
    ...mapState('wysq-step', ['nextItem', 'prevItem']),
    // ...mapGetters(['selectedBsdtCode']),
    ...mapState('wysq-data', ['flowInfoIng']),
    ...mapState('taxStatus', ['taxStatus']),
  },
  created() {
    this.userInfo = localDataUser.get();
    this.isGpy = localDataUser.get().gpybh;
    this.getProcessInfo(); //获取流程申请信息
    this.getSystemTime(); //获取系统时间
    this.targetToStep('材料上传');
  },
  methods: {
    // 更新流程信息
    ...mapMutations('wysq-data', ['setFlowInfoIng']),
    // 跳转到下一页面
    ...mapMutations('wysq-step', ['targetToStep']),
    // 跳转到上一页面
    prev() {
      this.targetToStep('材料上传');
      if (this.isApply) {
        this.$router.push({
          name: 'applicantTable',
          params: { ywh: this.flowInfoIng.wwywh, prev: true },
        });
      } else {
        this.$router.push({
          name: this.prevItem.name,
        });
      }
    },
    // 跳转到下一页面,需先校验当前页面数据状态 TODO
    next() {
      if (!this.flowInfoIng.wwywh) return;
      this.targetToStep('材料上传');
      // if (this.step === 'VERIFYSUCCESS' || !this.$hasPermission('IEBDC:SQ:SQRZ')) {
      this.$router.push({
        name: this.nextItem.name,
      });
      // } else {
      // this.$message.warning('请先返回上一步完成身份认证！');
      // }
    },
    startLoading() {
      this.loading = Loading.service({
        lock: true,
        text: '拼命加载中...', //可以自定义文字
        spinner: 'el-icon-loading', //自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.7)', //遮罩层背景色
        target: '.wrap', // 需要遮罩的区域
        customClass: 'mask',
      });
    },
    endLoading() {
      this.loading.close();
    },
    //查看示例
    checkSl(item) {
      this.demo = [];
      this.openUploadInfo.visible = true;
      this.openUploadInfo.title = `拍摄${item.clmc}要求`;
      this.demo = item.uploadfileConfig.demo || [];
    },
    //上传文件前处理文件，文件限制
    fileListHandle(file, item) {
      let fileSuffix = file.name.substring(file.name.lastIndexOf('.'), file.name.length);
      let filePre = item.clmc + (item.fileListData.length + this.fileList.length + 1);
      let fileAttr = {
        name: filePre + fileSuffix,
        fname: filePre + Date.now() + fileSuffix,
        type: file.type,
      };
      let obj = this.getFileObject();
      // 修改上传文件名，重新整理进fileList
      this.fileList.push({
        fname: fileAttr.fname,
        file: new obj([file], fileAttr.fname),
        attr: this.singleUpload ? Object.assign(fileAttr, { pageType: file.pageType }) : fileAttr,
      });
      console.log(item.fileListData, this.fileList, 'shang');
    },
    showMorePic(item) {
      item.showMore = !item.showMore;
    },
    //文件限制
    acceptFile(file) {
      const filetypes = ['.jpg', '.jpeg', '.png', '.pdf', '.mp4', '.mov'];
      let type = file.name.substring(file.name.lastIndexOf('.'));
      for (var i = 0; i < filetypes.length; i++) {
        if (filetypes[i] === type.toLowerCase()) {
          return true;
        }
      }
      this.$message.error('请上传.jpg,.jpeg,.png,.pdf类型文件');
    },
    // 上传文件前
    beforeUploadHandle(file, item) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
        return;
      }

      //判断是否为可上传类型
      if (!this.acceptFile(file)) return;
      this.fileListHandle(file, item);
      clearTimeout(this.fileTimeout);
      this.fileTimeout = setTimeout(() => {
        //文件整理成功后上传到服务器
        this.uploadFileChange(this.fileList, item);
      }, 100);
      return false;
    },
    //本地上传
    localUploadHandle(file, item) {
      // this.fileList = [];
      this.singleUpload = false;
      this.beforeUploadHandle(file, item);
    },
    //获取合同材料并提取
    getHt(item) {
      applicantUploadService
        .dzhtQuery({
          userId: this.userInfo.id,
          htbh: this.htbh,
        })
        .then((res) => {
          if (res.data && res.success) {
            this.singleUpload = false;
            let f = this.base64UrlToBlob('data:image/jpg;base64,' + res.data);
            f.name = item.clmc + '.pdf';
            this.beforeUploadHandle(f, item);
          } else {
            this.$message.error('提取合同失败');
          }
        });
    },
    //base64转二进制
    base64UrlToBlob(urlData) {
      //去掉url的头，并转换为byte
      if (!urlData) {
        return false;
      }
      let bytes = window.atob(urlData.split(',')[1]);
      //处理异常,将ascii码小于0的转换为大于0
      let ab = new ArrayBuffer(bytes.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], { type: 'application/pdf' });
    },
    // 获取兼容对象
    getFileObject() {
      // 兼容处理
      let obj = File;
      let ua = navigator.userAgent;
      if (/msie/i.test(ua) || 'ActiveXObject' in window) {
        obj = Blob;
      } else {
        // /firefox/i.test(ua) || /chrome/i.test(ua)
        obj = File;
      }
      return obj;
    },
    beforeRead(formData, file) {
      //async await 解决异步问题，
    },
    getUploadParams(fileList, clmc) {
      let formData = new FormData();
      //   this.beforeRead(formData, fileList);

      if (fileList.length) {
        //file.length为真的时候说明是多图上传  要循环多图将file对象放进form中
        for (let i = 0; i < fileList.length; i++) {
          if (fileList[i].name.indexOf('.pdf') == -1) {
            if (fileList[i].file.size == 0) {
              this.$message.error('文件为空');
              this.endLoading();
              return;
            }
            var f = compressImg.compressImg(fileList[i].file).then((file) => {
              formData.append('file', file, fileList[i].fname);
              formData.append('dir', this.djjg + '/' + this.systemTime + '/' + this.flowInfoIng.wwywh + '/' + clmc);
              formData.append('recover', true);
              return formData;
            }); //await只能放在async函数里 await会等待异步方法的返回值拿到以后在执行后面的方法
          } else {
            //pdf文件
            formData.append('file', fileList[i].file, fileList[i].fname);
            formData.append('dir', this.djjg + '/' + this.systemTime + '/' + this.flowInfoIng.wwywh + '/' + clmc);
            formData.append('recover', true);
          }
        }
      } else {
        let f = compressImg.compressImg(fileList.file).then((file) => {
          formData.append('file', file);
        });
      }
      //   fileList.forEach(({ file, fname }) => {
      //     formData.append('file', file, fname);
      //   });
      //上传的磁盘地址
    },
    getSaveUploadParams(data, fileList, item) {
      const {
        uploadfileConfig: { cllb },
        clsm,
        clmc,
      } = Object.assign(item, { uploadfileConfig: { cllb: '' } });
      return data.map(({ info: { filePathUrl, id } }, index) => ({
        clfl: clmc,
        cllb,
        clmc: this.singleUpload
          ? `${fileList[index].attr.pageType}${fileList[index].attr.name}`
          : fileList[index].attr.name,
        clsm,
        fileId: id,
        fileUrl: filePathUrl,
        show: true,
        sxh: item.fileListData.length + index + 1,
        ywh: this.flowInfoIng.wwywh,
      }));
    },
    /**
     * 附件文件保存到服务器端
     * @param fileList
     * @param clmc
     * @returns {Promise<void>}
     */
    async uploadFileChange(fileList, item) {
      this.startLoading();
      let formData = new FormData();
      //   this.beforeRead(formData, fileList);
      if (fileList.length) {
        //file.length为真的时候说明是多图上传  要循环多图将file对象放进form中
        for (let i = 0; i < fileList.length; i++) {
          console.log(fileList[i]);
          if (fileList[i].file.size == 0) {
            this.$message.error('文件' + fileList[i].fname + '不能为空文件');
            this.endLoading();
            continue;
          }
          //   if (
          //     fileList[i].fname.indexOf('.png') > -1 &&
          //     fileList[i].fname.indexOf('.jpg') > -1 &&
          //     fileList[i].fname.indexOf('.jpeg') > -1
          //   ) {
          //     var file = await compressImg.compressImg(fileList[i].file);
          //     formData.append('file', file, fileList[i].fname);
          //     formData.set('dir', this.djjg + '/' + this.systemTime + '/' + this.flowInfoIng.wwywh + '/' + item.clmc);
          //     formData.append('recover', true);
          //   } else {
          formData.append('file', fileList[i].file, fileList[i].fname);

          //   }
        }
        formData.append('recover', true);
        formData.append('dir', this.djjg + '/' + this.systemTime + '/' + this.flowInfoIng.wwywh + '/' + item.clmc);
        applicantUploadService
          .upLoadPicture(formData)
          .then((res) => {
            if (res.success) {
              this.saveFile(res.data, fileList, item);
            }
          })
          .catch((error) => {
            this.fileList = [];
            if (!error.success) this.endLoading();
          });
      } else {
        this.endLoading();
      }
    },
    saveFile(data, fileList, item) {
      applicantUploadService
        .saveFileUploadInfo({
          uploadfiles: this.getSaveUploadParams(data, fileList, item),
        })
        .then((res) => {
          if (res.success) {
            this.setFileList(res, item);
            // 刷新高拍仪数据
            this.init();
          } else {
            this.endLoading();
          }
        })
        .catch(() => {
          this.fileList = [];
          this.endLoading();
        });
    },

    /**
     * 保存到业务库后返回结果处理
     * @param result
     */
    setFileList(result, item) {
      this.fileList = [];
      const {
        uploadfileConfig: { cllb, backgrounds },
      } = item;

      // 非标准类材料
      (result.data || []).forEach((c) => {
        c.dataFormat = this.getDataFormat(c.clmc);
      });
      // 非标准类材料将文件直接整理进fileListData
      item.fileListData = item.fileListData.concat(result.data);
      this.endLoading();
    },
    // 标准类材料删除
    deleteNormalFile(fileArr, fileListData) {
      (fileArr || []).forEach((item) => {
        applicantUploadService.delImage({ id: item.fileId }).then((res) => {
          if (res.success) {
            //前端删除
            fileListData.forEach((pItem, i) => {
              if (pItem.clmc === item.clmc) {
                fileListData.splice(i, 1);
                fileListData = [...fileListData];
              }
            });
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    // 重新保存到业务库
    async postList(list, config, _id) {
      list.forEach((item, index) => {
        let tip = '';
        (config.tips || []).forEach((i) => {
          if (item.clmc.indexOf(i) >= 0) {
            tip = tip + i;
          }
        });
        item.sxh = index + 1;
        item.clmc =
          tip + item.clfl + item.sxh + '.' + item.clmc.substring(item.clmc.lastIndexOf('.') + 1, item.clmc.length);
      });
      //   if (list.length > 0) {
      //     // const { success, data } = await applicantUploadService.saveFileUploadInfo({
      //     //   uploadfiles: list,
      //     // });
      //     // if (success && data) {
      //     //   (data || []).forEach((i) => {
      //     //     i.dataFormat = this.getDataFormat(i.clmc);
      //     //   });
      //     //   this.fileData.filter(({ id }) => id === _id)[0].fileListData = data;
      //     // } else {
      //     //   this.$message.error('保存失败');
      //     //   this.endLoading();
      //     // }
      //   } else {
      //     this.endLoading();
      //     this.fileData.filter(({ id }) => id === _id)[0].fileListData = [];
      //   }
      //   this.endLoading();
      this.fileData.filter(({ id }) => id === _id)[0].fileListData = [];
    },
    //获取系统时间
    async getSystemTime() {
      const res = await applicantUploadService.getSystemTime();
      this.systemTime = res.data;
    },
    //高拍仪插件方法
    openGPY(item) {
      this.reInitGPY(item, true);
      this.openGPYModel(false, item);
    },
    reInitGPY(item, flag) {
      console.log(item);
      this.singleUpload = false;
      // this.selectedName = item.clmc;
      this.selectedItem = item;
      this.openUploadInfo.title = `拍摄${item.clmc}要求`;
      // this.demo = item.uploadfileConfig.demo || [];
      //flag为true代表合同类上传 可上传多张图片
      this.multiple = flag;
    },
    openGPYModel(singleFile, item) {
      if (this.isGpy) {
        this.currentGPYObject = item;
        // 若单文件上传则携带ID
        this.init(item);
        this.singleUpload = false;
        this.$refs.GpyModelRef.setSingleFile(singleFile || false);
        this.$refs.GpyModelRef.setFileNamePrefix(item.clmc);
        if (item) {
          // this.$refs.GpyModelRef.setTips(item.uploadfileConfig.tips || []);
        }
        this.$refs.GpyModelRef.visible = true;
      } else {
        this.$message.error('请前往用户中心选择高拍仪！');
      }
    },
    // 删除选中类别的所有图片
    delAllFiles(fileObj) {
      // 删除操作
      this.$confirm('是否删除' + fileObj.clmc + '相关的所有材料？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          try {
            let files = fileObj.fileListData;
            let ids = '';
            files.forEach((item, index) => {
              if (index === files.length - 1) {
                ids += item.id;
              } else {
                ids += item.id + ',';
              }
            });
            applicantUploadService.deleteFileByIdList({ ids: ids }).then((res) => {
              if (res.success === true) {
                this.$message.success('删除成功！');
                fileObj.fileListData = [];
              } else {
                this.$message.error(res.message);
              }
            });
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    //获取流程详细信息
    getProcessInfo() {
      if (!this.flowInfoIng.wwywh) return;
      applicantUploadService.getProcessInfo({ wwywh: this.flowInfoIng.wwywh }).then((res) => {
        const { success, data, message } = res;
        this.step = data.step;
        if (success && data) {
          if (data.step === 'VERIFICATION' || data.step === 'VERIFYSUCCESS') {
            this.isApply = false;
          } else if (data.step === 'NETAPPLYING') {
            this.isApply = true;
          }
          this.check = this.checkdisabled;
          this.djjg = data.djjg;
          this.subCode = data.subFlowcode;
          this.subFlowname = data.subFlowname;
          this.htbh = data.children[0].qlxxEx.htbh || '';
          this.sqrArrange(data.children);
          if (data.taxStatus === '2') {
            this.txtShow = true;
          }
        } else {
          this.$message.error(message);
        }
      });
    },
    //子母业务号版本 整合流程的所有权利人及义务人
    sqrArrange(arr) {
      //情况所有权利人义务人信息
      this.qlrInfoList = []; //权利人
      this.ywrInfoList = []; //义务人
      (arr || []).forEach((item) => {
        this.qlrInfoList = this.qlrInfoList.concat(this.addAttr(item.qlrs));
        this.ywrInfoList = this.ywrInfoList.concat(this.addAttr(item.ywrs));
      });
      //进入材料列表页面，获取材料已上传数量
      this.getFileList();
    },
    //给所有权利人和义务人加上name属性值为qlrmc || ywrmc,增加申请人类型
    addAttr(arr) {
      (arr || []).map((item) => {
        item.name = item.qlrmc || item.ywrmc;
        item.type = item.qlrlx === '1' ? '1' : '2'; //1个人 2企业
      });
      return arr;
    },
    //获取文件材料列表
    // 1、拿到材料分类，对需要和人相关的材料背景进行处理，backgrounds里面循环qlr或ywr，遍历为name是权利人/义务人名称+材料名称（如：身份证正面）+证件号，背景还是背景图片
    // 2、页面对三种类型分类展示，根据材料类别做三种循环
    async getFileList() {
      const { success, data, message } = await applicantUploadService.getFileList({
        subCode: this.subCode,
      });
      if (success && data) {
        this.rangeFileData(data);
      } else {
        // this.$message.error(message);
      }
    },
    //根据材料分类 判断是否与人相关
    async rangeFileData(files = []) {
      // let _this = this;
      // files = files
      //   .map((item) => {
      //     if (item.uploadfileConfig.cllb !== 'fbzcl' && item.uploadfileConfig.cllb !== 'bzcl') {
      //       let list = [];
      //       switch (item.uploadfileConfig.cllb) {
      //         case 'sqrxgcl':
      //           list = _this.qlrInfoList.concat(_this.ywrInfoList);
      //           break;
      //         case 'qlrxgcl':
      //           list = _this.qlrInfoList;
      //           break;
      //         case 'ywrxgcl':
      //           list = _this.ywrInfoList;
      //           break;
      //       }
      //       item.uploadfileConfig.backgrounds = _this.repeatBackgrounds(item, _this.uniqueInfoList(list));
      //     }

      //     item.uploadfileConfig.backgrounds = item.uploadfileConfig.backgrounds.map((item) => {
      //       item.allName = item.allName || '';
      //       item.uploadUrl = item.uploadUrl || '';
      //       return item;
      //     });
      //     return item;
      //   })
      //   .filter(({ sfzs }) => +sfzs === 1 || sfzs === true || sfzs === 'true');
      const { success, data, message } = await applicantUploadService.getUploadFilesCurrent({
        ywh: this.flowInfoIng.wwywh,
      });
      if (success && data) {
        this.fileData = this.arrangeFileList(files, data);
        this.refreshView();
      } else {
        this.$message.error(message);
      }
    },
    httpRequest() {},
    //刷新上传附件页面
    refresh() {
      this.getProcessInfo();
    },
    // 刷新视图
    refreshView() {
      this.needUpdateKey = new Date().getTime();
    },
    // 打印申请书
    printApply() {
      printApply({ ywh: this.flowInfoIng.wwywh }).then((res) => {
        if (res.success === true) {
          window.open(res.data);
        }
      });
    },
    async init() {
      const { success, data, message } = await applicantUploadService.getUploadFilesCurrent({
        ywh: this.flowInfoIng.wwywh,
      });
      if (success && data) {
        let tempList = data.filter((c) => c.clfl === this.currentGPYObject.clmc);
        // 必须排序
        tempList = tempList.sort(function (a, b) {
          return a.sxh - b.sxh;
        });
        this.gpyFileList = tempList;
        // this.$refs.GpyModelRef.ready = false;
      } else {
        this.$message.error(message);
      }
    },
    //所有权利人和义务人去重(key: 名字、证件号)
    uniqueInfoList(arr) {
      let list = [];
      for (let i = 0; i < arr.length; i++) {
        if (list.filter((item) => item.name === arr[i].name && item.zjh === arr[i].zjh).length === 0) {
          list.push(arr[i]);
        }
      }
      return list;
    },
    //根据权利人 义务人 申请人类型遍历backgrounds
    repeatBackgrounds(item, arr) {
      return arr.reduce(
        (total, { type, name, zjh }) =>
          total.concat(
            item.uploadfileConfig.backgrounds.reduce(
              (array, data) =>
                array.concat(
                  type === data.type
                    ? Object.assign({}, data, {
                        name: `${name}${data.name}${zjh}`,
                      })
                    : []
                ),
              []
            )
          ),
        []
      );
    },
    //整理已上传的文件到文件列表中
    arrangeFileList(fileData, data) {
      (data || []).forEach((item) => {
        item.fileUrl = item.fileUrl + '?time=' + new Date().getTime();
      });
      let _this = this;
      (fileData || []).forEach((file) => {
        console.log(data, 'file1');
        file.fileListData = !file.fileListData ? [] : file.fileListData;
        (data || []).forEach((pItem) => {
          if (pItem.clfl === file.clmc) {
            if (file.uploadfileConfig)
              //根据文件名找到对应的background添加uploadUrl和dataFormat属性
              (file.uploadfileConfig.backgrounds || []).forEach((jItem) => {
                let name = pItem.clmc.substring(0, pItem.clmc.indexOf('.'));
                if (jItem.name === name) {
                  jItem.uploadUrl = pItem.fileUrl;
                  console.log(jItem.uploadUrl);
                  jItem.dataFormat = _this.getDataFormat(pItem.clmc);
                  jItem.allName = pItem.clmc;
                }
              });

            pItem.dataFormat = _this.getDataFormat(pItem.clmc);
            file.fileListData = file.fileListData.concat(pItem);
          }
        });
        file.fileListData = file.fileListData.sort((a, b) => a.sxh - b.sxh);
        file.showMore = false;
      });
      return fileData;
    },
    //获取文件类型
    getDataFormat(clmc) {
      const dataFormat = clmc.split('.');
      if (
        dataFormat[dataFormat.length - 1].toLowerCase() === 'jpg' ||
        dataFormat[dataFormat.length - 1].toLowerCase() === 'jpeg' ||
        dataFormat[dataFormat.length - 1].toLowerCase() === 'png'
      ) {
        return 'img';
      } else if (dataFormat[dataFormat.length - 1].toLowerCase() === 'pdf') {
        return 'pdf';
      } else {
        return '';
      }
    },
    //是否显示自动获取材料按钮
    isGetCL(name) {
      if (this.$hasPermission('IEBDC:SCFJ:HQCL') && this.userInfo.name) {
        if (name.indexOf('身份证') > -1) {
          return {
            name: '获取身份信息',
            isShowBtn: true,
          };
        }
      } else {
        return {
          isShowBtn: false,
        };
      }
    },
    //保存图片
    // save() {
    //   this.$message.success('保存成功！');
    // },
    goStep(name) {
      this.$router.push({
        name,
      });
    },
    //手机上传
    // async phoneUpload() {
    //   this.openUploadPhone.visible = true;
    //   const { success, data } = await applicantUploadService.getEwm({
    //     ywh: this.flowInfoIng.wwywh,
    //     subCode: this.subCode,
    //     url: window.CONFIG.ewm_path,
    //     intend: '123'
    //   });
    //   if (success && data) {
    //     this.ewmImg = data;
    //   } else {
    //     this.$message.error('二维码获取失败！');
    //   }
    // },
    //上传完成
    phoneFinishApp() {
      //重新获取上传材料
      this.getFileList();
      //关闭弹窗
      //上传成功关闭弹窗
      this.openUploadPhone.visible = false;
    },
    // 高拍仪上传
    uploadHandle(list) {
      if (this.$refs.GpyModelRef.singleFile) {
        this.beforeUploadHandle(list[list.length - 1], this.selectedItem);
      } else {
        this.singleUpload = true;
        list.forEach((c) => {
          this.beforeUploadHandle(c, this.selectedItem);
        });
      }
    },
    // 刷新高拍仪数据
    async updateHandle(list) {
      // 保存到业务库
      const result = await applicantUploadService.saveFileUploadInfo({
        uploadfiles: list,
      });
      if (result.success) {
        this.getFileList();
      }
    },
  },
  watch: {
    //深度监听fileData,判断文件必填项是否上传完成，显示下一步按钮
    fileData: {
      handler(fileData) {
        //控制子组件file-list的样式END
        let completedFile = true;
        (fileData || []).forEach((pItem) => {
          //非标准材料，如合同类
          if (pItem.sfbx && pItem.fileListData.length <= 0) {
            completedFile = false;
          }
        });
        this.showNextTag = completedFile;
        // todo 暂时去掉
        // if (this.step === 'VERIFYSUCCESS') {
        //   this.showNextTag = completedFile;
        // }
      },
      // immediate: true,  //刷新加载 立马触发一次handler
      deep: true, // 可以深度检测到 fileData 对象的属性值的变化
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/pages/iebdc/styles/public.scss';
@import '~@/pages/iebdc/styles/common-variables.scss';
.wrap-title {
  padding: $spacing-base 0 $spacing-base $spacing-base;
  font-size: 16px;
  margin-top: 10px;
  padding: 8px 0;
}

.type {
  line-height: 32px;
  margin-bottom: 10px;
  span {
    font-weight: 700;
  }
  button {
    float: right;
  }
}
.zlscBox {
  border-bottom: 1px solid #cccccc;
  margin-bottom: 20px;
}
.filelist {
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  .filelist-title {
    padding: 10px 20px;
    border-top: 1px solid #cccccc;
    .block {
      width: calc(100% - 310px);
      display: inline-block;
      img {
        margin: -2px 5px;
      }
    }
    .block-check {
      width: calc(100% - 100px);
      display: inline-block;
      img {
        margin: -2px 5px;
      }
    }
    .clmc {
      font-size: 15px;
      color: #343434;
      line-height: 20px;
    }
    .redPoint {
      color: red;
      font-size: 16px;
      font-weight: 700;
    }
    .btn-wrap {
      width: 480px;
      float: right;
      text-align: right;
    }
    .btn-wrap-check {
      width: 100px;
      float: right;
      text-align: right;
    }
  }
  .wjylBox {
    border-bottom: 1px solid #cccccc;
  }
  .filelist-list {
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
    > div {
      width: 25%;
    }
  }
}

.demo-img {
  margin: 0 auto;
  display: block;
  height: 100%;
  /deep/.el-image__inner {
    object-fit: contain;
  }
}

.upload-demo {
  // width: 80px;
  display: inline-block;
  // margin-right: 10px;
}

.dialog .el-dialog {
  p {
    line-height: 25px;
  }
  .img-div {
    height: 170px;
    position: relative;
    img {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .img-name {
    text-align: center;
  }
  .redPoint {
    color: red;
    font-size: 14px;
    margin-left: 10px;
  }
  height: 500px;
}
.redPoint {
  color: red;
  font-size: 14px;
  margin-right: 10px;
}
.uploadPhone {
  text-align: center;
  img {
    display: block;
    width: 258px;
    margin: auto;
  }
}
.notice {
  padding: 5px 10px;
  border: 1px solid #ffa500;
  background-color: #ffdead;
  color: #333333;
  border-radius: 4px;
  font-size: 15px;
  p {
    line-height: 25px;
    span {
      font-weight: bold;
    }
  }
  .margin-left {
    margin-left: 45px;
  }
}
i.el-icon-star-off {
  color: #e7313a;
  width: 20px;
  text-align: left;
  font-size: 16px;
}
i.el-icon-view {
  color: #2c8bff;
  width: 30px;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
}
.apply-file {
  // width: 100px;
  text-align: center;
  line-height: 30px;
  //   border-bottom: 2px solid #2c8bff;
  .apply-file-title {
    border-bottom: 2px solid #2c8bff;
    color: #2c8bff;
    font-size: 18px;
  }
  .apply-ywh {
    color: #e7313a;
    font-size: 14px;
    // width: 100px;
    text-align: center;
    line-height: 30px;
    margin-left: 10px;
  }
}

// 上传按钮样式修改
.btn-compo {
  border: 1px solid #dcdfe6;
  // color: #2c8bff;
  height: 30px;
  border-radius: 3px;
  // width: 100px;
  padding: 0 10px;
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
  img,
  i {
    width: 20px;
    height: 20px;
    margin-top: 5px;
    float: left;
    margin-right: 3px;
    color: #2c8bff;
  }
  i {
    font-size: 20px;
  }
  span {
    float: left;
    height: 30px;
    line-height: 30px;
  }
}
// .btn-compo.width75 {
//   width: 75px;
// }
.btn-compo.refresh img {
  width: 16px;
  height: 16px;
  margin-top: 7px;
}
.btn-compo:hover {
  border: 1px solid #2c8bff;
}
/deep/ .el-dialog .el-dialog__header {
  padding: 8px 25px;
}
</style>
